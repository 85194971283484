import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTE_TO_EXTERNAL } from "../constants/routing-constants";

type LinkUtilProps = {
  to: any;
  id: any;
};

/**
 * Util to be used when a clickable link in dropdowns could be internal or external
 *
 * @param props
 * @returns
 */
export const LinkUtil = (props: React.PropsWithChildren<LinkUtilProps>) => {
  const [openedWindow, setOpenedWindow] = useState<Window | null>(null);

  const openOrRedirect = (page: string, url: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (openedWindow && !openedWindow.closed) {
      openedWindow.focus();
    } else {
      const newWindow = window.open(url, `${page}Frame`);
      setOpenedWindow(newWindow);
    }
  };

  if (ROUTE_TO_EXTERNAL.includes(props.to.label)) {
    return (
      <a
        id={props.to.label + "_linkId"}
        href={props.to.path}
        rel="noopener noreferrer"
        onClick={(e) => openOrRedirect(props.to.label, props.to.path, e)}
      >
        {props.children}
      </a>
    );
  }

  return (
    <Link
      to={props.to.path}
      id={props.to.label}
      key={props.id}
      state={{ titleKey: props.to.key || props.to.label }}
      reloadDocument={props.to.reloadDocument}
    >
      {props.children}
    </Link>
  );
};
