import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import ServerSideExportMenu from "../ServerSideExportCommon/ServerSideExportMenu/ServerSideExportMenu";

type Props = {
  open: boolean;
  onCloseCallbackFn?: Function;
};

export const LOSExportModal = (props: Props) => {
  const { open, onCloseCallbackFn } = props;
  return (
    <div className="los-export-modal">
      <LeafModal open={open} onCloseCallbackFn={onCloseCallbackFn} children={<ServerSideExportMenu />}></LeafModal>;
    </div>
  );
};
