import { useTranslation } from "react-i18next";
import commonContent from "../../../content/common";

export const LeafVolumeLabel = (volumeType: string) => {
  const { t } = useTranslation(["common"]);
  let label = "";
  switch (volumeType) {
    case "personalTransaction":
      label = t("pcpv", commonContent["pcpv"] as string).toString();
      break;
    case "personalActivityTransaction":
      label = t("papv", commonContent["papv"] as string).toString();
      break;
    case "group":
      label = t("gpv", commonContent["gpv"] as string).toString();
      break;
    case "personal":
      label = t("ppv", commonContent["ppv"] as string).toString();
      break;
    case "ruby":
      label = t("ruby", commonContent["ruby"] as string).toString();
      break;
    case "vcspv":
      label = t("vcspv", commonContent["vcspv"] as string).toString();
      break;
    case "member":
      label = t("cpv", commonContent["cpv"] as string).toString();
      break;
    default:
      break;
  }
  return label;
};
